import { NavLink } from "react-router-dom";
import { auth, db } from "../firebase";
import { useState } from "react";
import { arrayUnion, collection, deleteDoc, doc, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default function InstruFreq() {

    const [instructor, setInstructor] = useState(auth?.currentUser?.email)

    const [sessions, setSessions] = useState("")

    const [actualWeek, setActualWeek] = useState([]) //aktualny tydzień
    const [weekSelector, setWeekSelector] = useState(0) //liczba do zmian daty
    const [actualDay, setActualDay] = useState(new Date()?.setHours(0,0,0,0))

    const [currentFreq, setCurrentFreq] = useState("")
    const [freq, setFreq] = useState([])

    const [event, setEvent] = useState("")
    const [error, setError] = useState(false)

    const [anim, setAnim] = useState("")

    useEffect(() => {
        getWeek(new Date)
    }, [weekSelector]);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setInstructor(user.email); 
          } else {
            setInstructor(null); 
          }
        });
    
        return () => unsubscribe();
      }, []);

    useEffect(() => {
        subscribeToInstructorSessions();
    }, [instructor]);

    useEffect(() => {
        subscribeToInstructorSessions();
    }, [actualDay]);

    const convertTimestampToDate = (timestamp) => {
        const date = new Date(timestamp); // Tworzymy obiekt Date z timestampu
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są indeksowane od 0
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const subscribeToInstructorSessions = () => {
        if (!instructor) return

        const sessionsRef = collection(db, 'sessions');

        const instructorSessionsQuery = query(sessionsRef, where('date', '==', convertTimestampToDate(actualDay)), where("instructors", "array-contains", instructor));
        // const instructorSessionsQuery = query(sessionsRef, where('date', '==', convertTimestampToDate(actualDay)))

        onSnapshot(instructorSessionsQuery, (snapshot) => {
          const sessionsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setSessions(sessionsData);
        });
    };

    const getWeek = (d) => {
        const week = [];
        d.setDate((d.getDate() - d.getDay()) + (7 * weekSelector))
        for (let i = 0; i < 7; i++) {
            d?.setHours(0,0,0,0)
            week.push(new Date(d))
            d.setDate(d.getDate() + 1)
        }
        setActualWeek(week)
    }

    const toBigLetter = (t) => {
        if (t === undefined) return

        let text = t?.charAt(0)?.toUpperCase() + t?.slice(1)
        return text
    }

    const weekDayToDay = (d) => {
        if (d === 1) return "PON"
        if (d === 2) return "WT"
        if (d === 3) return "ŚR"
        if (d === 4) return "CZW"
        if (d === 5) return "PT"
        if (d === 6) return "SOB"
        if (d === 0) return "NDZ"
    }

    const changeToBeStatus = (userName, uid) => {
        const updatedUsers = freq.map(user =>
          user.displayName === userName && user?.uid === uid ? { ...user, toBe: !user?.toBe } : user
        );
        setFreq(updatedUsers);
    };

    const changeFreq = () => {
        setAnim(true)
        setDoc(doc(db, "sessions", currentFreq?.id), {
            users: freq,
            note: currentFreq?.note ? currentFreq?.note : ""
        }, {merge: true})

        setFreq([])
        setCurrentFreq("")
    }

    const addEvent = async () => {
        if (!event?.date || !event?.title || !event?.startTime || !event?.endTime || !event?.location) {
            setError("Nie uzupełniono wszystkich pól!")
            return
        }

        setAnim(true)
        const sessionDocRef = doc(collection(db, 'sessions')); 
      
        await setDoc(sessionDocRef, {
          id: sessionDocRef.id,
          date: event?.date,
          startTime: event?.startTime,
          endTime: event?.endTime,
          status: 'scheduled',
          location: event?.location,
          name: event?.title,
          color: event?.location === "Sala Gajowicka" ? "#D0142D" : event?.location === "Sala Północna Jastrzębie" ? "#3949AB" : "rgb(30,30,30)",
          type: "reservation",
          instructors: event?.location === "Sala Gajowicka" ? arrayUnion("gemma11@o2.pl", "justyna.m.wojda@gmail.com", "bodakalicja@gmail.com", "warzechaola@wp.pl", "majewska.lo12@gmail.com", "kontakt@fitwriterka.com", "weronika0316@wp.pl", "alicja.dluzniak1@gmail.com", "ol.natalia04@gmail.com", "kaspor1@gmail.com", "izamat07@gmail.com", "daszkiewicz.julia.16@gmail.com", "pdworniczek@gmail.com", "malgorzata.a.kowalska@interia.pl", "ala01stasiak@gmail.com", "karolina.buczaniewicz@gmail.com", "kaparnik.karolina@gmail.com", "kingarozbicka6@gmail.com", "kp.katarzyna.pelc@gmail.com", "karolina97szymanska@gmail.com", "coretaxandmore@gmail.com") : event?.location === "Sala Północna Jastrzębie" ? arrayUnion("gemma11@o2.pl", "timchenko.katya86@gmail.com", "magdalena.procek@op.pl", "emiliakowalczuk2003@gmail.com", "grzadziel.milena@gmail.com", "emilakrodance@gmail.com") : arrayUnion("gemma11@o2.pl"),
          reservator: auth?.currentUser?.email === "gemma11@o2.pl" ? "Monika Mączka-Gąsior" : auth?.currentUser?.email === "bodakalicja@gmail.com" ? "Alicja Bodak" : auth?.currentUser?.email === "alicja.dluzniak1@gmail.com" ? "Alicja Dłużniak" : auth?.currentUser?.email === "grzadziel.milena@gmail.com" ? "Milena Grządziel" : auth?.currentUser?.email === "emilakrodance@gmail.com" ? "Emilia Kowalczuk" : auth?.currentUser?.email === "timchenko.katya86@gmail.com" ? "Katya Timchenko" : "4STARS"
        }, { merge: true });

        setEvent("")
    }

    const editEvent = async () => {
        setAnim(true)
        const ref = doc(db, 'sessions', event.id); 
      
        await updateDoc(ref, {
          date: event?.date,
          startTime: event?.startTime,
          endTime: event?.endTime,
          name: event?.name,
        }, { merge: true });

        setEvent("")
    }

    const deleteEvent = async () => {
        setAnim(true)
        const ref = doc(db, 'sessions', event.id); 
        await deleteDoc(ref)

        setEvent("")
    }

    const getD = (date) => {
        const offset = date.getTimezoneOffset()
        date = new Date(date.getTime() - (offset*60*1000)) 

        return date?.toISOString().split('T')[0]
    }

    useEffect(() => {
        const timer = setTimeout(() => {
          setAnim(false);
        }, 1500);
    
        return () => clearTimeout(timer);
    }, [anim === true]); 

    return (
        !currentFreq ? (
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", backgroundColor: "rgb(250,250,250)", width: "100%", minHeight: "100vh", overflowY: "scroll", gap: "15px"})}>
                {anim &&
                    <div style={({display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", gap: "15px", position: "fixed", top: "0px", left: "0px", minHeight: "100vh", zIndex: "5", backgroundColor: "rgb(250,250,250)"})}>
                        <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        <h3 style={({fontSize: "18px", color: "rgb(20,20,20)"})}>Zapisywanie zmian...</h3>
                    </div>
                }
                <div style={({display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "90%", marginTop: "20px"})}>
                    {(actualWeek && actualWeek[0]) &&  <h3 style={({fontWeight: "bold", fontSize: "15px", fontStyle: "italic", letterSpacing: "-0.3px"})}>{toBigLetter(actualWeek[1]?.toLocaleDateString("pl", {month: "long"}))}</h3>}
                </div>
                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "90%"})}>
                    <img onClick={() => {setWeekSelector(weekSelector - 1)}} src={process.env.PUBLIC_URL + "/arrow.png"} style={({width: "12px", transform: "rotate(180deg)", cursor: "pointer"})} />
                    {actualWeek?.map((d) => 
                        <div onClick={() => setActualDay(d?.getTime())} style={({display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "7px", cursor: "pointer"})}>
                            <h3 style={({fontWeight: "bold", fontSize: "12px", color: actualDay === d?.getTime() ? "#0045c7" : new Date()?.setHours(0,0,0,0) === d?.getTime() ? "rgb(30,30,30)" : "rgb(30,30,30,0.35)", transition: "0.6s", fontStyle: "italic", letterSpacing: "-0.3px"})}>{weekDayToDay(d?.getDay())}</h3>
                            <h3 style={({fontWeight: "bold", fontSize: "12px", color: actualDay === d?.getTime() ? "#0045c7" : new Date()?.setHours(0,0,0,0) === d?.getTime() ? "rgb(30,30,30)" : "rgb(30,30,30,0.35)", transition: "0.6s", fontStyle: "italic", letterSpacing: "-0.3px"})}>{d?.toLocaleDateString("pl", {day: "2-digit"})}</h3>
                        </div>
                    )}
                    <img onClick={() => {setWeekSelector(weekSelector + 1)}} src={process.env.PUBLIC_URL + "/arrow.png"} style={({width: "12px", cursor: "pointer"})} />
                </div>
                <hr style={({width: "85%", border: "1px solid rgb(0,0,0,0.03)"})}/>
                {(actualDay && !event) && 
                    <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "85%"})}>
                        <div style={({display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "25px"})}>
                            <div style={({display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"})}>
                                <h3 style={({fontWeight: "bold", fontSize: "20px", color: "#0045c7", fontStyle: "italic"})}>{new Date(actualDay)?.toLocaleDateString("pl", {day: "2-digit"})}</h3>
                                <h3 style={({fontWeight: "bold", fontSize: "13px", color: "#0045c7", fontStyle: "italic"})}>{weekDayToDay(new Date(actualDay)?.getDay())}</h3>
                            </div>
                            <div style={({display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start"})}>
                                {actualDay && <h3 style={({fontWeight: "bold", fontSize: "17px", color: "rgb(30,30,30)", fontStyle: "italic"})}>{((new Date(actualDay)?.setHours(0,0,0,0) - new Date()?.setHours(0,0,0,0)) / 86400000) === 0 ? "Dzisiaj" : ((new Date(actualDay)?.setHours(0,0,0,0) - new Date()?.setHours(0,0,0,0)) / 86400000) === 1 ? "Jutro" : ((new Date(actualDay)?.setHours(0,0,0,0) - new Date()?.setHours(0,0,0,0)) / 86400000) === -1 ? Math?.abs((new Date(actualDay)?.setHours(0,0,0,0) - new Date()?.setHours(0,0,0,0)) / 86400000) + " dzień temu": ((new Date(actualDay)?.setHours(0,0,0,0) - new Date()?.setHours(0,0,0,0)) / 86400000) < 0 ? Math?.abs((new Date(actualDay)?.setHours(0,0,0,0) - new Date()?.setHours(0,0,0,0)) / 86400000) + " dni temu" : "Za " + Math.round((new Date(actualDay)?.setHours(0,0,0,0) - new Date()?.setHours(0,0,0,0)) / 86400000) + " dni"}</h3>}
                                {/* {days && Object?.values(days)?.filter(x => x?.date === actualDay)[0] && <h3 style={({fontWeight: "bold", fontSize: "13px", color: "rgb(0,0,0,0.3)"})}>{getEventsNumber()}</h3>} */}
                            </div>
                        </div>
                        <img src={process.env.PUBLIC_URL + "/add_icon.png"} style={({width: "30px", cursor: "pointer"})} onClick={() => setEvent({state: 0})}/>
                    </div>
                }
                {!event && <hr style={({width: "85%", border: "1px solid rgb(0,0,0,0.03)"})}/>}
                {(sessions && sessions?.length > 0 && !event) &&
                    sessions?.sort((a, b) => a.startTime.localeCompare(b.startTime))?.map((s) => 
                        <>
                            {s?.type === "lesson" &&
                                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", cursor: "pointer", padding: "10px", width: "92%", transition: "0.5s", cursor: "pointer", height: "85px", paddingBottom: "15px", paddingTop: "10px", borderRadius: "5px"})} onClick={() => {setCurrentFreq(s); setFreq(s?.users)}}>         
                                    <div style={({display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", gap: "15px", height: "100%"})}>
                                        <div style={({width: "3px", height: "97.5px", backgroundColor: s?.color, borderRadius: "20px"})} />
                                        <div style={({display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "flex-start", gap: "5px", height: "100%"})}>
                                            <h3 style={({color: s?.color, fontSize: "13px", transition: "0.5s", fontWeight: "bold", marginBottom: "2px", fontStyle: "italic", letterSpacing: "-0.3px"})}>{s?.startTime + " - " + s?.endTime}</h3>
                                            <h3 style={({color: s?.color, fontSize: "13px", transition: "0.5s", fontStyle: "italic", letterSpacing: "-0.3px"})}>{s?.name} {s?.maxAge === "99" ? `Dorośli ${s?.minAge}+` : s?.minAge + "-" + s?.maxAge} lat</h3>
                                            <h3 style={({color: "rgb(30,30,30)", fontSize: "12px", transition: "0.5s", fontWeight: "normal", marginTop: "-3px"})}>{s?.location}</h3>
                                            <h3 style={({color: s?.color, fontSize: "12px", transition: "0.5s", fontWeight: "bold", marginTop: "4px", fontStyle: "italic", letterSpacing: "-0.3px"})}>p: {s?.exp}</h3>
                                        </div>
                                    </div>
                                    <div style={({display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "flex-end", gap: "12px", height: "100%"})}>
                                        <h3 style={({color: s?.color, fontSize: "11px", transition: "0.5s", fontWeight: "bold", marginTop: "4px", fontStyle: "italic", letterSpacing: "-0.3px"})}>{(s?.users ? s?.users?.length : "0") + "/" + s?.slots} osób</h3>
                                    </div>
                                </div>
                            }
                            {s?.type === "reservation" &&
                                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", padding: "10px", width: "92%", transition: "0.5s", cursor: "pointer", height: "85px", paddingBottom: "15px", paddingTop: "10px", borderRadius: "5px"})} onClick={() => setEvent({...s, state: 2})}>         
                                    <div style={({display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", gap: "15px", height: "100%"})}>
                                        <div style={({width: "3px", height: "97.5px", backgroundColor: s?.color, borderRadius: "20px"})} />
                                        <div style={({display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "flex-start", gap: "5px", height: "100%"})}>
                                            <h3 style={({color: s?.color, fontSize: "13px", transition: "0.5s", fontWeight: "bold", marginBottom: "2px", fontStyle: "italic", letterSpacing: "-0.3px"})}>{s?.startTime + " - " + s?.endTime}</h3>
                                            <h3 style={({color: s?.color, fontSize: "13px", transition: "0.5s", fontStyle: "italic", letterSpacing: "-0.3px"})}>Rezerwacja sali - {s?.name}</h3>
                                            <h3 style={({color: "rgb(30,30,30)", fontSize: "12px", transition: "0.5s", fontWeight: "normal", marginTop: "-3px"})}>{s?.location}</h3>
                                            <h3 style={({color: s?.color, fontSize: "12px", transition: "0.5s", fontWeight: "bold", marginTop: "4px", fontStyle: "italic", letterSpacing: "-0.3px"})}>{s?.reservator}</h3>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    )
                }
                {event &&
                    <div style={({display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", width: "90%", marginTop: "10px", padding: "5px", gap: "12px", marginBottom: "60px"})}>
                        <div style={({display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%", marginBottom: "10px"})}>
                            <h3 style={({color: "rgb(30,30,30)", fontSize: "18px", transition: "0.5s"})}>{event?.state === 2 ? "Edytuj wydarzenie" : "Dodaj wydarzenie"}</h3>
                            <img src={process.env.PUBLIC_URL + "/logout_black.png"} style={({width: "22px", cursor: "pointer"})} onClick={() => setEvent("")}/>
                        </div>       
                        {event?.state === 0 && 
                            <div style={({display: "flex", justifyContent: "center", alignItems: "center", width: "100%", gap: "0px", flexDirection: "column"})}>
                                <div style={({display: "flex", justifyContent: "center", alignItems: "center", width: "100%", gap: "8px"})}>
                                    <div style={({gap: "12px", display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", borderRadius: "5px", cursor: 'pointer', boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.5px, rgb(209, 213, 219) 0px 0px 0px 1px", border: "2px solid rgba(0,0,0,0)", transition: "0.5s", width: "90%", height: "50px", padding: "5px 20px"})} onClick={() => setEvent({...event, type: "reservation", state: 1, date: getD(new Date(actualDay))})}>
                                        <img src={process.env.PUBLIC_URL + "/reservation_white.png"} style={({width: "45px"})}/>
                                        <div style={({display: "flex", flexDirection: "column", gap: "0px", justifyContent: 'flex-start', alignItems: "flex-start"})}>
                                            <h3 style={({color: "#0045C7", fontWeight: "bold", textAlign: "center", fontSize: "17px"})}>Rezerwacja</h3>
                                            <h3 style={({color: "#0045C7", fontWeight: "normal", textAlign: "center", fontSize: "14px", marginTop: "-4px"})}>Rezerwacja sali lub godzin.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {event?.state === 1 && 
                            <>
                                <h3 style={({color: "rgb(50,50,50)", fontSize: "12px", transition: "0.5s", marginTop: "5px", marginBottom: "-7px"})}>Data rezerwacji</h3>
                                <input type="date" defaultValue={event?.date} style={({height: "10px", width: "90%", padding: "12px", maxWidth: "350px", outline: "none", fontWeight: "normal", backgroundColor: "rgb(0,0,0,0)", border: "1px solid rgb(30,30,30,0.2)", color: "rgb(60,60,60)", fontSize: "12px", borderRadius: "5px", marginBottom: "5px"})} onBlur={(e) => setEvent({...event, date: e?.target?.value })}/>
                                <h3 style={({color: "rgb(50,50,50)", fontSize: "12px", transition: "0.5s", marginTop: "5px", marginBottom: "-7px"})}>Tytuł rezerwacji</h3>
                                <input type="text"  style={({height: "10px", width: "90%", padding: "12px", maxWidth: "350px", outline: "none", fontWeight: "normal", backgroundColor: "rgb(0,0,0,0)", border: "1px solid rgb(30,30,30,0.2)", color: "rgb(60,60,60)", fontSize: "12px", borderRadius: "5px", marginBottom: "5px"})} onBlur={(e) => setEvent({...event, title: e?.target?.value })}/>
                                <div style={({display: "flex", flexDirection: "row", width: "90%", justifyContent: "flex-start", alignItems: "center", gap: "40px"})}>
                                    <div style={({display: "flex", flexDirection: "column", width: "40%", justifyContent: "center", alignItems: "flex-start", gap: '4px'})}>
                                        <h3 style={({color: "rgb(50,50,50)", fontSize: "12px", transition: "0.5s", marginTop: "5px"})}>Godzina rozpoczęcia</h3>
                                        <input type="time" style={({height: "10px", width: "100%", padding: "12px", outline: "none", fontWeight: "normal", backgroundColor: "rgb(0,0,0,0)", border: "1px solid rgb(30,30,30,0.2)", color: "rgb(60,60,60)", fontSize: "12px", borderRadius: "5px", marginBottom: "5px"})} onBlur={(e) => setEvent({...event, startTime: e?.target?.value })}/>
                                    </div>
                                    <div style={({display: "flex", flexDirection: "column", width: "40%", justifyContent: "center", alignItems: "flex-start", gap: '4px'})}>
                                        <h3 style={({color: "rgb(50,50,50)", fontSize: "12px", transition: "0.5s", marginTop: "5px"})}>Godzina zakończenia</h3>
                                        <input type="time"  style={({height: "10px", width: "100%", padding: "12px", outline: "none", fontWeight: "normal", backgroundColor: "rgb(0,0,0,0)", border: "1px solid rgb(30,30,30,0.2)", color: "rgb(60,60,60)", fontSize: "12px", borderRadius: "5px", marginBottom: "5px"})} onBlur={(e) => setEvent({...event, endTime: e?.target?.value })}/>
                                    </div>
                                </div>
                                <div style={({display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "flex-start", gap: '4px'})}>
                                    <h3 style={({color: "rgb(50,50,50)", fontSize: "12px", transition: "0.5s", marginTop: "5px"})}>Rezerwowana sala</h3>
                                    <select value={event?.location} onChange={(e) => setEvent({...event, location: e.target.value})} style={({height: "35px", padding: "7px", width: "100%", maxWidth: "370px", outline: "none", fontWeight: "normal", backgroundColor: "rgb(0,0,0,0)", border: "1px solid rgb(30,30,30,0.15)", color: "rgb(90,90,90)", fontSize: "12px", borderRadius: "5px"})}>
                                        <option value={""} key={-1}>---</option>
                                        <option value={"Sala Gajowicka"} key={0}>Sala Gajowicka</option>
                                        <option value={"Sala Północna Jastrzębie"} key={1}>Sala Północna Jastrzębie</option>
                                    </select>
                                </div>
                                <button style={({width: "99%", maxWidth: "400px", height: "35px", fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "20px"})} className="button-black" onClick={() => addEvent()}>Dodaj wydarzenie</button>
                            </>
                        }
                        {event?.state === 2 && 
                            <>
                                <h3 style={({color: "rgb(50,50,50)", fontSize: "12px", transition: "0.5s", marginTop: "5px", marginBottom: "-7px"})}>Data rezerwacji</h3>
                                <input defaultValue={event?.date} type="date" style={({height: "10px", width: "90%", padding: "12px", maxWidth: "350px", outline: "none", fontWeight: "normal", backgroundColor: "rgb(0,0,0,0)", border: "1px solid rgb(30,30,30,0.2)", color: "rgb(60,60,60)", fontSize: "12px", borderRadius: "5px", marginBottom: "5px"})} onBlur={(e) => setEvent({...event, date: e?.target?.value })}/>
                                <h3 style={({color: "rgb(50,50,50)", fontSize: "12px", transition: "0.5s", marginTop: "5px", marginBottom: "-7px"})}>Tytuł rezerwacji</h3>
                                <input defaultValue={event?.name} type="text"  style={({height: "10px", width: "90%", padding: "12px", maxWidth: "350px", outline: "none", fontWeight: "normal", backgroundColor: "rgb(0,0,0,0)", border: "1px solid rgb(30,30,30,0.2)", color: "rgb(60,60,60)", fontSize: "12px", borderRadius: "5px", marginBottom: "5px"})} onBlur={(e) => setEvent({...event, name: e?.target?.value })}/>
                                <div style={({display: "flex", flexDirection: "row", width: "90%", justifyContent: "flex-start", alignItems: "center", gap: "40px"})}>
                                    <div style={({display: "flex", flexDirection: "column", width: "40%", justifyContent: "center", alignItems: "flex-start", gap: '4px'})}>
                                        <h3 style={({color: "rgb(50,50,50)", fontSize: "12px", transition: "0.5s", marginTop: "5px"})}>Godzina rozpoczęcia</h3>
                                        <input defaultValue={event?.startTime} type="time" style={({height: "10px", width: "100%", padding: "12px", outline: "none", fontWeight: "normal", backgroundColor: "rgb(0,0,0,0)", border: "1px solid rgb(30,30,30,0.2)", color: "rgb(60,60,60)", fontSize: "12px", borderRadius: "5px", marginBottom: "5px"})} onBlur={(e) => setEvent({...event, startTime: e?.target?.value })}/>
                                    </div>
                                    <div style={({display: "flex", flexDirection: "column", width: "40%", justifyContent: "center", alignItems: "flex-start", gap: '4px'})}>
                                        <h3 style={({color: "rgb(50,50,50)", fontSize: "12px", transition: "0.5s", marginTop: "5px"})}>Godzina zakończenia</h3>
                                        <input defaultValue={event?.endTime} type="time"  style={({height: "10px", width: "100%", padding: "12px", outline: "none", fontWeight: "normal", backgroundColor: "rgb(0,0,0,0)", border: "1px solid rgb(30,30,30,0.2)", color: "rgb(60,60,60)", fontSize: "12px", borderRadius: "5px", marginBottom: "5px"})} onBlur={(e) => setEvent({...event, endTime: e?.target?.value })}/>
                                    </div>
                                </div>
                                <div style={({display: "flex", flexDirection: "row", width: "99%", justifyContent: "flex-start", alignItems: "center", gap: "3px"})}>
                                    <button style={({width: "70%", maxWidth: "400px", height: "35px", fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "20px"})} className="button-black" onClick={() => editEvent()}>Edytuj wydarzenie</button>
                                    <button style={({width: "27%", maxWidth: "200px", height: "35px", fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "20px"})} className="button-red" onClick={() => setEvent({...event, state: 3})}>Usuń</button>
                                </div>
                            </>
                        }
                        {event?.state === 3 && 
                            <>
                                <h3 style={({color: "rgb(30,30,30)", fontSize: "18px", transition: "0.5s", marginTop: "40px", textAlign: "center", width: "99%", maxWidth: "600px"})}>Czy na pewno chcesz usunąć rezerwację?</h3>
                                <div style={({display: "flex", flexDirection: "row", width: "99%", justifyContent: "flex-start", alignItems: "center", gap: "3px"})}>
                                    <button style={({width: "70%", maxWidth: "400px", height: "35px", fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "20px"})} className="button-red" onClick={() => deleteEvent()}>Usuń wydarzenie</button>
                                    <button style={({width: "27%", maxWidth: "200px", height: "35px", fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "20px"})} className="button-black" onClick={() => setEvent({...event, state: 2})}>Anuluj</button>
                                </div>
                            </>
                        }
                    </div>
                }
            </div>
        ):(
            <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", backgroundColor: "rgb(250,250,250)", width: "100%", minHeight: "100vh", overflowY: "scroll", gap: "15px"})}>
                {anim &&
                    <div style={({display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", gap: "15px", position: "fixed", top: "0px", left: "0px", minHeight: "100vh", zIndex: "5", backgroundColor: "rgb(250,250,250)"})}>
                        <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        <h3 style={({fontSize: "18px", color: "rgb(20,20,20)"})}>Zapisywanie zmian...</h3>
                    </div>
                }
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", gap: "10px", width: "90%", marginTop: "20px", padding: "5px", gap: "20px", marginBottom: "20px", position: "relative"})}>
                    <img src={process.env.PUBLIC_URL + "/logout_black.png"} style={({width: "22px", cursor: "pointer",  position: "absolute", right: "10px", top: "10px"})} onClick={() => {setCurrentFreq(""); setFreq([])}}/>
                    <h3 style={({color: "rgb(50,50,50)", fontSize: "14px", transition: "0.5s", marginTop: "5px", maxWidth: "240px"})}>{currentFreq?.location} - {currentFreq?.name} {currentFreq?.maxAge === "99" ? `Dorośli ${currentFreq?.minAge}+` : currentFreq?.minAge + "-" + currentFreq?.maxAge} lat</h3>
                    <h3 style={({color: "rgb(50,50,50)", fontSize: "13px", transition: "0.5s", marginTop: "-18px", fontWeight: "normal"})}>{currentFreq?.date} {currentFreq?.startTime + "-" + currentFreq?.endTime} {currentFreq?.id} {currentFreq?.instructors[0]}</h3>
                    <h3 style={({color: "rgb(50,50,50)", fontSize: "15px", transition: "0.5s", marginTop: "5px", marginBottom: "-10px"})}>Uczestnicy:</h3>
                    {freq?.sort((a,b) => a?.displayName?.localeCompare(b?.displayName))?.map((u) =>
                        <div style={({display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "15px"})}>
                            <div style={({minWidth: "22px", minHeight: "22px", borderRadius: "4px", display: 'flex', justifyContent: "center", alignItems: "center", marginRight: "8px", backgroundColor: u?.toBe === true ? "#12B552" : "#CF265C"})} onClick={() => changeToBeStatus(u?.displayName, u?.uid)}/>
                            <img style={({width: "30px"})} src={u?.gender === "girl" ? process.env.PUBLIC_URL + "/girl-icon.png" : u?.gender === "boy" ? process.env.PUBLIC_URL + "/boy-icon.png" : u?.gender === "woman" ? process.env.PUBLIC_URL + "/women-icon.png" : process.env.PUBLIC_URL + "/user-icon.png"} />
                            <div style={({display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start", gap: "2px", marginLeft: "-3px"})}>
                                <h3 style={({color: "rgb(20,20,20)", fontSize: "12px", transition: "0.5s"})}>{u?.displayName} {u?.pickUp && "- Odebrać - " + u?.class}</h3>
                                <h3 style={({color: "rgb(90,90,90)", fontSize: "11px", transition: "0.5s"})}>{u?.phone}</h3>
                                {u?.info && <h3 style={({color: "#CF265C", fontSize: "12px", transition: "0.5s"})}>{u?.info}</h3>}
                            </div>
                        </div>
                    )}
                    <h3 style={({color: "rgb(50,50,50)", fontSize: "12px", transition: "0.5s", width: "90%", textAlign: "left", marginTop: "5px", marginBottom: "-12px"})}>Notatka dodatkowa</h3>
                    <textarea defaultValue={currentFreq?.note} placeholder="Informacja warta odnotowania o zajęciach." style={({height: "80px", width: "90%", padding: "12px", maxWidth: "350px", outline: "none", fontWeight: "normal", backgroundColor: "rgb(0,0,0,0)", border: "1px solid rgb(30,30,30,0.2)", color: "rgb(90,90,90)", fontSize: "12px", borderRadius: "5px"})} onBlur={(e) => setCurrentFreq({...currentFreq, note: e?.target?.value})}/>
                    <button style={({width: "99%", maxWidth: "400px", height: "35px", fontSize: "13px", color: "white", borderRadius: "5px"})} className="button-black" onClick={() => changeFreq()}>Zapisz zmiany</button>
                </div>
            </div>
        )
    )
}