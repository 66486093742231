import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

export default function HomePage() {

  const [data, setData] = useState({})
  const [state, setState] = useState(0)

  const [send, setSend] = useState("")

  const [error, setError] = useState("")

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const login = async () => {
    try {
      setLoading(true)
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, data?.email, data?.password);
      if (data.email === "gemma11@o2.pl" || data?.email === "bodakalicja@gmail.com" || data?.email === "justyna.m.wojda@gmail.com" || data?.email === "timchenko.katya86@gmail.com" || data?.email === "magdalena.procek@op.pl" || data?.email === "emiliakowalczuk2003@gmail.com" || data?.email === "warzechaola@wp.pl" || data?.email === "majewska.lo12@gmail.com" || data?.email === "grzadziel.milena@gmail.com" || data?.email === "kontakt@fitwriterka.com" || data?.email === "WERONIKA0316@wp.pl" || data?.email === "weronika0316@wp.pl" || data?.email === "alicja.dluzniak1@gmail.com" || data?.email === "ol.natalia04@gmail.com" || data?.email === "kaspor1@gmail.com" || data?.email === "izamat07@gmail.com" || data?.email === "daszkiewicz.julia.16@gmail.com" || data?.email === "pdworniczek@gmail.com" || data?.email === "malgorzata.a.kowalska@interia.pl" || data?.email === "emilakrodance@gmail.com" || data?.email === "ala01stasiak@gmail.com" || data?.email === "karolina.buczaniewicz@gmail.com" || data?.email === "majakubalanca@gmail.com" || data?.email === "kaparnik.karolina@gmail.com" || data?.email === "kingarozbicka6@gmail.com" || data?.email === "kp.katarzyna.pelc@gmail.com" || data?.email === "karolina97szymanska@gmail.com" || data?.email === "coretaxandmore@gmail.com") {
        navigate("/frekwencja")
      }
      else {
        navigate("/panel-klienta")
      }
      setLoading(false)
    } catch (error) {
      setError('Niepoprawne dane logowania, spróbuj ponownie');
      setLoading(false)
    }
}

  const resetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, data?.email);
      setSend("Wiadomość email z resetem hasła została wysłana, sprawdź swoją pocztę")
    } catch (error) {
      setError("Błąd podzczas resetowania hasła, spróbuj ponownie później")
    }
  };

    return (
      <div style={({display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundColor: "rgb(240,240,240)", width: "100%", minHeight: "100vh", overflowY: "scroll", gap: "30px"})}>
        {state === 0 &&
          <div style={({display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "15px", width: "80%"})}>
            <img src="logosvg.svg" style={({width: "150px", marginBottom: "-40px"})} />
            <h3 style={({fontSize: "30px", color: "rgb(30,30,30)", textAlign: "center"})}>Witaj ponownie!</h3>
            <h3 style={({fontSize: "14px", color: "rgb(30,30,30)", fontWeight: "lighter", textAlign: "center", marginTop: "-10px", marginBottom: "20px"})}>Nie jesteś jeszcze członkiem żadnej placówki? Skontaktuj się z nami i zapisz się już teraz!</h3>
            <input className="login" type="email" placeholder="Adres email" style={({height: "25px", width: "270px", fontWeight: "normal", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/email.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "8px 8.5px", paddingLeft: "40px", transition: "0.8s", border: "1px solid rgb(30,30,30)", borderRadius: "5px"})} onBlur={(e) => {setData({...data, email: e?.target?.value}); setError("")}}/>
            <input className="login" type="password" placeholder="Hasło" style={({height: "25px", width: "270px", fontWeight: "normal", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/pass.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "8px 8.5px", paddingLeft: "40px", transition: "0.8s", border: "1px solid rgb(30,30,30)", borderRadius: "5px"})} onBlur={(e) => {setData({...data, password: e?.target?.value}); setError("")}}/>
            <h3 style={({fontSize: "14px", color: "#0045C7", fontWeight: "lighter", textAlign: "center", marginTop: "50px", marginBottom: '-10px'})} onClick={() => setState(1)}>Nie pamiętasz hasła? <u>Kliknij tutaj</u></h3>
            <button style={({fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "10px", backgroundColor: "#0045C7", border: "2px solid #0045C7", transition: "0.7s", fontWeight: "bold", padding: "10px 30px", width: "250px"})} onClick={() => login()} disabled={loading}>{loading ? "Proszę czekać..." : "Zaloguj się"}</button>
            {error && <h3 style={({fontSize: "14px", color: "#e60000", fontWeight: "bold", textAlign: "center", marginTop: "50px", marginTop: "10px"})}>{error}</h3>}
          </div>
        }
        {state === 1 &&
          <div style={({display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "15px", width: "80%"})}>
            <img src="logosvg.svg" style={({width: "150px", marginBottom: "-40px"})} />
            <h3 style={({fontSize: "30px", color: "rgb(30,30,30)", textAlign: "center"})}>Zresetuj hasło</h3>
            <h3 style={({fontSize: "14px", color: "rgb(30,30,30)", fontWeight: "lighter", textAlign: "center", marginTop: "-10px", marginBottom: "20px"})}>Wpisz swój adres email, a dostaniesz na niego wiadomość z linkiem, przez który zresetujesz swoje hasło.</h3>
            {send && <h3 style={({fontSize: "14px", color: "#0045C7", fontWeight: "lighter", textAlign: "center", marginTop: "50px", marginTop: '-10px'})}>{send}</h3>}
            <input className="login" type="email" placeholder="Adres email" style={({height: "25px", width: "270px", fontWeight: "normal", appearance: "none", border: "none", outline: "none", backgroundColor: "rgb(250,250,250)", padding: ".4em", color: "rgb(20,20,20)", backgroundImage: `url(${process.env.PUBLIC_URL + "/email.png"})`, backgroundSize: "17px", backgroundRepeat: "no-repeat", backgroundPosition: "8px 8.5px", paddingLeft: "40px", transition: "0.8s", border: "1px solid rgb(30,30,30)", borderRadius: "5px"})} onBlur={(e) => {setData({...data, email: e?.target?.value}); setError("")}}/>
            <button style={({fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "10px", backgroundColor: "#0045C7", border: "2px solid #0045C7", transition: "0.7s", fontWeight: "bold", padding: "10px 30px", width: "250px"})} onClick={() => resetPassword()}>Resetuj</button>
            <button style={({fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "10px", backgroundColor: "#0045C7", border: "2px solid #0045C7", transition: "0.7s", fontWeight: "bold", padding: "10px 30px", marginTop: "-5px", width: "250px"})} onClick={() => setState(0)}>Powrót</button>
          </div>
        }
      </div>
    );
  }